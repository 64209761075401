import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  AppState,
  CollapseSidebar,
  DiscoveryChannelState,
  FilterTxnOmniChannel,
  NameAppStore,
  PER_PAGE_DISCOVERY_CHANNEL,
  SidebarTabs,
  SplitMode,
  ViewTxn,
  initStateMyWorksV2
} from './app-state.model';

export function createInitialState(): AppState {
  return <AppState>{
    sidebarTabActive: SidebarTabs.teamchat,
    emailUWState: {
      isExpandPersonal: true,
      isExpandTeam: false,
      isExpandTeammate: false
    },
    mentionCountTeamChat: 0,
    quillEditor: {
      triggerfocus: false
    },
    popupState: {
      isShowPopup: false,
      isLoadingTemplate: false,
      tag: {}
    },
    isLeftChatSidebar: false,
    triggerRefreshTxn: false,
    customerTxnReports: [],
    collapseSidebar: <CollapseSidebar>{
      inboxes: true,
      me: false,
      starred: false,
      watching: true,
      related: true,
      created: true,
      channels: false
    },
    splitMode: SplitMode.horizontalSplit,
    isFetchingTxnDetail: false,
    recentTxns: [],
    isOpenLeftSidebar: true,
    isOpenInternalSpace: true,
    discoveryChannelState: <DiscoveryChannelState>{
      loaded: false,
      page: 1,
      perPage: PER_PAGE_DISCOVERY_CHANNEL,
      hasMore: false
    },

    // omni
    channelsByLicense: [],
    latestViewMyWorks: ViewTxn.assigned,
    myWorkV2: initStateMyWorksV2(),
    pendingTxns: [],
    omniChannelFilter: <FilterTxnOmniChannel>{
      filter: {
        statuses: [],
        inboxUuids: [],
        reporters: []
      },
      textSearch: '',
      txnUuidsFilter: [],
      state: {
        loaded: false,
        page: 1,
        perPage: 50,
        hasMore: false
      }
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: NameAppStore })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}
