import { MemberStatus, Team } from '@b3networks/api/auth';
import { RequestDetailLeaves } from '@b3networks/api/leave';
import { ROBIN_BOT_IDENTITY, encodeUserId } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';

export class UserDirectoryBase {
  chatUserId: string; // encode from orgUuid + identityUuid, input orgUuid to encode if empty/null
  memberUuid: string; // identityUuid
  memberStatus: MemberStatus;
  name: string;
  photoUrl: string;
  lastUpdated: number;

  // optional
  orgUuid?: string; // can null
  extensionKey?: string;
  extensionLabel?: string;
  email?: string;
  mobileNumber?: string;
  about?: string;
  teamIds?: string[];

  constructor(obj: Partial<UserDirectoryBase>) {
    if (obj) {
      Object.assign(this, obj);

      try {
        if (!this.chatUserId && (!this.orgUuid || !this.memberUuid)) {
          throw new Error();
        }

        if (!this.chatUserId && this.orgUuid && this.memberUuid) {
          this.chatUserId = encodeUserId(this.orgUuid, this.memberUuid);
        }
      } catch (error) {
        console.error('chatUserId need check: ', this);
      }
    }
  }

  get userUuid() {
    return this.chatUserId;
  }
  set userUuid(chatUserId: string) {
    this.chatUserId = chatUserId;
  }

  get uuid() {
    return this.memberUuid;
  }
  set uuid(identity: string) {
    this.memberUuid = identity;
  }

  get displayName() {
    return this.name?.trim();
  }
  set displayName(value: string) {
    this.name = value;
  }

  get identityUuid() {
    return this.memberUuid;
  }
  set identityUuid(identityUuid: string) {
    this.memberUuid = identityUuid;
  }

  get isActive(): boolean {
    return this.memberStatus === MemberStatus.active;
  }
}

export interface UserDirectoryUI {
  chatUserId: string | ID; // id entity
  loadedDetailFromAuth: boolean; // get about for user
  loadedTeams: boolean;
  teams: Team[];
}

export class UserDirectory extends UserDirectoryBase {
  //ui
  isTemporary?: boolean; // get all user v2
  icon?: string;
  isDeleted?: boolean; // check the user has been removed from the member list.

  isAgent?: boolean;
  isBot?: boolean;
  requestLeaveNow?: RequestDetailLeaves;

  static fromIntegrationJson(json: RequestIntegration) {
    if (!json.uuid) return null;
    return new UserDirectory({
      memberUuid: json.uuid,
      chatUserId: json.msChatUuid ?? '',
      name: json.name?.trim() ?? '',
      photoUrl: json.photoUrl ?? '',
      orgUuid: json.orgUuid ?? '',

      isBot: true,
      isTemporary: true,
      memberStatus: MemberStatus.active
    });
  }

  static fromAgentJson(json: CallCenterAgent) {
    if (!json.identityUuid) return null;
    return new UserDirectory({
      memberUuid: json.identityUuid,
      orgUuid: json.orgUuid,
      name: json.displayName ?? '',
      photoUrl: json.photoUrl ?? '',
      email: json.email,
      isAgent: true,
      isTemporary: true,
      memberStatus: MemberStatus.active
    });
  }

  constructor(obj?: Partial<UserDirectory>) {
    super(obj);
    if (obj) {
      if (obj.isTemporary) this.isTemporary = obj.isTemporary;
      if (obj.icon) this.icon = obj.icon;
      if (obj.isAgent) this.isAgent = obj.isAgent;
      if (obj.isBot) this.isBot = obj.isBot;

      this.setIcon();
    }
  }

  get photoUrlOrDefault() {
    return this.photoUrl && this.photoUrl.indexOf('http') > -1
      ? this.photoUrl
      : 'https://ui.b3networks.com/external/logo/default_org_icon.png';
  }

  get hasPhoto() {
    return !!this.photoUrl && this.photoUrl.indexOf('http') > -1;
  }

  private setIcon() {
    if (this.isBot) {
      if (this.identityUuid === ROBIN_BOT_IDENTITY) {
        this.icon = 'school';
      } else {
        this.icon = 'smart_toy';
      }
    } else {
      this.icon = 'person';
    }
  }
}

export interface StatusUserResponse {
  state: UserStatus;
  ts: number;
}

export interface RequestIntegration {
  msChatUuid: string;
  uuid: string;
  orgUuid: string;
  name: string;
  photoUrl: string;
  createdBy: string;
  createdAt: Date;
}

export interface CallCenterAgent {
  displayName: string;
  email: string;
  identityUuid: string;
  photoUrl: string;

  orgUuid?: string; // optional
}

export enum UserStatus {
  online = 'online',
  offline = 'offline',
  idle = 'idle'
}
